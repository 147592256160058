.student-link {
    color: #4447ad !important;
    text-decoration: none;
    display: flex;
    padding: 0 !important;
    text-transform: none !important;
    cursor: pointer !important;
}

.student-link:hover {
    color: #b39fe7 !important;
    background-color: transparent !important;
}


.student-table thead th {
    background-color: white;
    border-bottom: 1.5px solid lightgray;
    font-weight: bolder;
}
