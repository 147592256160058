body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.background-image {
  position: absolute;
  background-image: url('/public/images/iStock-1598479059-Edit.jpg');
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 1;
}

.app-header {
  position: relative;
  display: flex;
  justify-content: space-around;
  max-height: 10vh;
  padding: 5px 20px 5px 20px;
  z-index: 2;
  flex: 1;
}

#bienen-logo {
  max-width: 50%;
  height: auto;
  object-fit: contain;
}
#lurie-logo {
  height: 7rem;;
  max-width: 50%;
  object-fit: contain;
  position: relative;
  top: -20px;
}

#root {
  position: relative;
  z-index: 2 !important;
  margin: 2rem;
  margin-bottom: 0;
  flex: 3;
  display: flex;
  flex-direction: column;
}

.app-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  row-gap: 0.5em;
  padding: 0.75em 0px;
  bottom: 0;
  z-index: 2;
  line-height: 1.5;
  width: 100%;
}


@media (max-width: 840px) {
  #bienen-logo {
    width: 300px;

  }
  #lurie-logo {
    height: 5rem;
    position: relative;
    top: -10px;
  }
  #bienen-logo {
    height: 5rem;
    position: relative;
    top: -10px;
  }
}

@media (max-width: 540px) {
  .app-header {
    flex-direction: column;
    align-items: center;
  }
  .app-header img {
    min-height: 5rem;
  }
  #lurie-logo {
    max-width: 80%;
    position: relative;
    top: -30px;
  }
  #bienen-logo {
    max-width: 80%;
  }
}